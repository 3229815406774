import React from 'react'

const Transactions = () => {
    return (
        <div>
            <h2>Transactions</h2>
        </div>
    )
}

export default Transactions
